import React from 'react'
import { Link } from 'gatsby'

const Sidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link>
                        <form className="search-box">
                            <input type="text" className="input-search" placeholder="Search Company" />
                            <button type="submit">
                                <i className="flaticon-loupe"></i>
                            </button>
                        </form>
                    </Link>

                </li>
                <li style={{ color: 'white', fontSize: '20px' }}>
                    <Link>
                        Our Business
                    </Link>
                </li>
                <li>
                    <Link to="/trading">
                        <div style={{ marginLeft: '30px' }}>
                            Trading
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/fisheries">
                        <div style={{ marginLeft: '30px' }}>
                            Fisheries
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/fmcg">
                        <div style={{ marginLeft: '30px' }}>
                            FMCG
                        </div>
                    </Link>

                </li>
                <li>
                    <Link to="/energy">
                        <div style={{ marginLeft: '30px' }}>
                            Renewable Energy
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/fleet">
                        <div style={{ marginLeft: '30px' }}>
                            Fleet
                        </div>
                    </Link>
                </li>

                <li>
                    <Link to="#">
                        <div style={{ marginLeft: '30px' }}>
                            &nbsp;
                        </div>
                    </Link>
                </li>

                <li>
                    <Link to="#">
                        <div style={{ marginLeft: '30px' }}>
                            &nbsp;
                        </div>
                    </Link>
                </li>
 
            </ul>
            

        </div>
    )
}

export default Sidebar